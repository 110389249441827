#authentication-panel-holder {
    & > .card {
        width: 90%;
        @include media-breakpoint-up(md) {
            width: 50%;
        }
        @include media-breakpoint-up(lg) {
            width: 25%;
        }
    }
}

.loginSys__barTop {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	background: #212226;
	z-index: 10;
}

.loginSys__barBottom {
	 position: absolute;
	 bottom: 0;
	 left: 0;
	 width: 100%;
	 background: #212226;
	 z-index: 10;
}

.loginSys__barDiv {
	height: 50px;
}

.loginSys__section {
	position: absolute;
	width: 100%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.loginSys__form {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.loginSys__form-container {
	width: 30%;
    margin:auto;
    display: flex;
    color:#f8f9fa;
    flex-direction: column;
/*    box-shadow: 1px 2px 71px -6px rgba(0,0,0,0.67);*/
    height:fit-content;
    background-color: #212226;
    padding:20px 20px;
    border-radius: 10px;
}

@media (max-width: 1000px) { .loginSys__form-container { width: 50%; } }
@media (max-width: 500px) { .loginSys__form-container { width: 75%; } }


#Heading{
    padding:5px;
    text-align: center;
    font-size: 2em;
}
.loginSys__form-container label{
    margin: 10px 0px 5px 0px;
}

.loginSys__form-container input{
	margin: auto;
    padding:10px;
    width: 100%;
    height: 30px;
    border: solid #fff 3px;
    border-radius: 10px;
    background-color: #fff;
}

.loginSys__form-container input:focus{
    outline: none;
    border: solid #f4b31b 3px;
    background-color: none;
}


.loginSys__form-container button, .loginSys__popup-btns{

/*    transition-duration: 0.25s;*/
    font-size: 1.1em;
    border-radius: 20px;
    background-color:  #f4b31b;
    color: #212226;
    font-weight: bold;
    padding: 10px;
    cursor: pointer;
    border: none;
    outline: none;
    margin-bottom: 10px;
}

.loginSys__form-container button:hover{
    background-color: #c18d15;
}

.loginSys__form-container span{
	text-align: right;
/*    display: flex;*/
/*    justify-content: space-between;*/

}
.loginSys__form-container h6{
    text-decoration: none;
    color:#f4b31b;
    cursor: pointer;
}
.loginSys__form-container span>span>a{
    margin:0px 0px
}
.loginSys__form-container a:hover{
    color: #c18d15;
}

.loginSys__popup-container {
    width: 75%;
}

.loginSys__popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginSys__popup-inner {
  background-color: #212226;
  padding: 20px;
  border-radius: 20px;
  width: 40%;
  // width: 100%;
  text-align: center;
}

@media (max-width: 1000px) { .loginSys__popup-inner { width: 60%; } }
@media (max-width: 500px) { .loginSys__popup-inner { width: 85%; } }

.loginSys__close-btn {
  margin-top: 10px;
}

// .loginSys__popup-btns {
//   width: 50%;
// }