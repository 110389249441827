.product-images {
    &, .row > div {
        position: relative;
    }
    .delete-image-button {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}
ul.pldos-alt-barcodes {
    & > li {
        position: relative;

        button.btn-danger {
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
}
.pldos-barcode-editor {
    text-align: center;

    & > .pldos-barcode {
        border: solid 1px $dark;
        border-radius: 10px;
        padding: 10px 7.5px 0 7.5px;

        &, & > div {
            display: inline-block;
        }
    }
}
