$primary: #f4b31b;
$info: #0658c2;
$warning: #edd396;

$nav-height: 56px;

@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.scss";
@import "~react-bootstrap-typeahead/css/Typeahead.css";
@import "~react-bootstrap-typeahead/css/Typeahead.bs5.css";

body {
    background-color: #e8e8e8;
}
.navbar button img {
    max-height: 1.3em;
}
main.loading {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    & > nav {
        flex: 0 0 auto;
    }
    & > .container {
        flex: 1 0 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.modal.lightbox {
    .modal-content {
        background-color: $dark;
    }
    .modal-header {
        border: 0;
    }
}
.carousel {
    .carousel-item {
        position: relative;
        & > img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &.h-100 > .carousel-inner {
        height: 100%;
    }
}
.ratio-box {
    position: relative;
    height: 0;
    overflow: hidden;
    
    & > div {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        & > img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    
    &.ratio-square {
        padding-top: 100%;
    }
    &.ratio-golden { // 3/2
        padding-top: 66.7%;
    }
}

.card.product {
    img {
        max-width: 100%;
        // max-height: 320px;
    }    
}

.pldos-smart-image {
    position: relative;
    display: inline-block;

    &, & > img {
        max-width: 100%;
        max-height: 100%;
    }

    & > div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        & > div {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &.pldos-smart-image-loading {
        & > img {
            opacity: 0;
        }
    }
}

#scanner-root {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: $zindex-modal-backdrop;
}

@import "./login.scss";
@import "./product.scss";
